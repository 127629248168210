<script>
export default {
  page: {
    title: "Time Limit"
  },
  data() {
    return {
      timelimit: {
        sod: null,
        eod: null
      }
    };
  },
  created() {
    // this.loadTable();
  },
  methods: {
    loadTable() {
      this.changeLoader(true);
      this.ApiService.get("timelimit")
        .then(resp => {
          this.notificationList = resp.data;
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    save() {},
    clearForm() {}
  }
};
</script>

<template>
  <div>
    <b-row>
      <b-col>
        <b-card title="System Closing Due Days" class="h-100">
          <hr />
          <b-form @submit.stop.prevent="save">
            <b-row class="mb-2">
              <b-col md="6" align-self="center">
                <label>Day count to sytem close on Own Leads</label>
                <b-form-input type="number" min="0" class="form-control" />
              </b-col>
            </b-row>
          </b-form>
          <b-card-footer>
            <b-btn type="submit" variant="success">Save</b-btn>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
